<template>
  <v-theme-provider light>
    <base-section
      id="slogan"
      class="secondary text-center"
      space="70"
    >
      <v-container>
        <h2 class=" text-h5 text-uppercase primary--text font-weight-bold mb-4 text-center">
          Wir unterstützen dein Breaking Talent!
        </h2>
      </v-container>
      <div class="py-4" />
    </base-section>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'SectionNews',
    provide: {
      heading: { align: 'center' },
    },
  }
</script>
<style scoped>
.v-application text-h4{
  letter-spacing: 0rem;
}
</style>
